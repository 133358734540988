
import {Options, Vue} from 'vue-class-component';
import MainPage from './components/MainPage.vue';

@Options({
  components: {
    MainPage,
  },
})
export default class App extends Vue {

}
